
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildDate": "2024-09-30T12:03:17.250Z",
  "nuxt": {
    "buildId": "e2f36022-4236-41f5-b9eb-83bcac563b74"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
